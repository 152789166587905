import Button from '@/component/Button'
import Input from '@/component/Input'
import { validatRequired, validateEmail, validatePassword } from '@/helper/validationUtils'
import { captchaEmail, register } from '@/server/user'
import { UserType } from '@/server/user/types'
import { Md5 } from 'ts-md5'

import { Message } from '@/component/Message'
import AuthContext from '@/context/AuthContext'
import useLangNavigate from '@/hook/useLangNavigate'
import { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Step from '../Step'

// 定义组件的props接口
interface StepProps {
  onTypeChange: (type: string) => void
  email: string
  setEmail: (email: string) => void
  onClose: () => void
}

const Register: FC<StepProps> = ({ onTypeChange, email, setEmail, onClose }) => {
  const { t } = useTranslation('login')
  const langNavigate = useLangNavigate()
  const auth = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})
  const [phonenumber, setPhonenumber] = useState('')
  const [code, setVerifyCode] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [countdown, setCountdown] = useState(0)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)

  const handleSendVerificationCode = async () => {
    if (countdown > 0) return // 防止重复点击
    setCountdown(60)
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) clearInterval(timer)
        return prev - 1
      })
    }, 1000)

    // 模拟发送验证码请求
    const res = await captchaEmail(email)
    if (res.code === 200) {
      Message.success(t('login:btn.submitSuccess'))
    } else {
      Message.error(t('login:btn.submitFail'))
    }
  }

  const validate = () => {
    const newErrors: { [key: string]: string | undefined } = {}
    newErrors.email = validateEmail(email)
    newErrors.password = validatePassword(password)
    newErrors.confirmPassword = password !== confirmPassword ? 'passwordMatch' : undefined
    newErrors.firstName = validatRequired(firstName)
    newErrors.lastName = validatRequired(lastName)
    newErrors.phonenumber = validatRequired(phonenumber)
    newErrors.code = validatRequired(code)
    if (!hasAcceptedTerms) {
      newErrors.terms = t('inputLabel.acceptError')
    }
    setErrors(newErrors)
    return Object.values(newErrors).every(error => !error)
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setLoading(true)
    const body: UserType = {
      email,
      username: email,
      firstName,
      lastName,
      password: Md5.hashStr(password),
      phonenumber,
      code,
      userType: 'app_user',
      roleKey: 'normal'
    }
    const newErrors: { [key: string]: string | undefined } = {}
    const res = await register(body)
    if (res.code === 200) {
      Message.success(t('login:registerSuccess'))
      localStorage.setItem('Authorization', res.data.token)
      await auth.getUserInfo()
      onClose()
    } else if (res.code === 2000) {
      newErrors.code = 'codeError'
      setErrors(newErrors)
    } else if (res.code === 20001) {
      newErrors.email = 'emailRule'
      setErrors(newErrors)
    } else {
      Message.error(t('login:btn.submitFail'))
    }
    setLoading(false)
  }

  return (
    <div className="my-10 sm:my-14">
      <Step current={2} onTypeChange={onTypeChange} />
      <div className="mt-7"></div>
      <Input
        label={t('inputLabel.email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={errors.email}
      />
      <Input
        label={t('inputLabel.phonenumber')}
        value={phonenumber}
        onChange={e => setPhonenumber(e.target.value)}
        error={errors.phonenumber}
      />
      <div className="flex w-full items-center space-x-4">
        <div className="flex-1">
          <Input
            label={t('inputLabel.emailVerifyCode')}
            value={code}
            onChange={e => setVerifyCode(e.target.value)}
            error={errors.code}
            className="w-full"
          />
        </div>
        <button
          onClick={handleSendVerificationCode}
          className="mt-2 h-[28px] w-auto rounded bg-[#f7931a] px-4 text-sm font-bold italic leading-none text-white"
          disabled={countdown > 0}
        >
          {countdown > 0 ? t('btn.resendCode', { countdown: countdown }) : t('btn.sendCode')}
        </button>
      </div>
      <div className="flex space-x-4">
        <Input
          label={t('inputLabel.firstName')}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          error={errors.firstName}
        />
        <Input
          label={t('inputLabel.lastName')}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          error={errors.lastName}
        />
      </div>
      <Input
        label={t('inputLabel.password')}
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={errors.password}
      />
      <Input
        label={t('inputLabel.confirmPassword')}
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        error={errors.confirmPassword}
      />
      <div className="mt-4 flex items-center">
        <input
          type="checkbox"
          checked={hasAcceptedTerms}
          onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
          className="mr-2 rounded text-[#f7931a]  accent-[#f7931a] "
        />
        <span className="text-sm text-[#c5c4c2]">{t('inputLabel.accept1')}</span>
        <span
          className="cursor-pointer text-sm font-bold italic text-[#c5c4c2] underline decoration-[#f7931a] underline-offset-4"
          onClick={() => langNavigate('/terms', true)}
        >
          {t('inputLabel.accept2')}
        </span>
      </div>
      {errors.terms && <p className="text-sm text-red-500">{errors.terms}</p>}
      <div className="flex w-full justify-end">
        <Button
          loading={loading}
          onClick={handleSubmit}
          className="mt-6 rounded-full px-8 py-2 font-bold italic sm:py-3"
        >
          {t('btn.register')}
        </Button>
      </div>
    </div>
  )
}

export default Register
