import api from '@/config/api'
import { getBaseHost } from '@/helper'
import { PageParams, ResultAny } from '@/type/common'
import {
  ResulConfigHours,
  ResultAccessory,
  ResultBay,
  ResultDiscount,
  ResultOrderList,
  ResultPlayMode,
  ResultPlayTime,
  ResultPurchaseList,
  ResultTradeList,
  SquarePaymentType,
  orderPaymentType,
  orderType
} from './types'

const apiHost = getBaseHost(process.env.NODE_ENV)

//获取价格区间 /app/config/playtime/price/range
export const getPriceRange = async (): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.get(`${apiHost}/app/config/playtime/price/range`)
    return response
  } catch (error: any) {
    return error
  }
}

// 获取营业时间 /app/config/hours/day
export const getConfigHours = async (date: string): Promise<ResulConfigHours> => {
  try {
    const response: ResulConfigHours = await api.get(`${apiHost}/app/config/hours/day`, {
      params: {
        date: date
      }
    })
    return response
  } catch (error: any) {
    return error
  }
}

//查询游玩时间配置列表 /app/config/playtime/list
export const getConfigPlayTimeList = async (params: PageParams): Promise<ResultPlayTime> => {
  try {
    const response: ResultPlayTime = await api.get(`${apiHost}/app/config/playtime/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//查询游玩模式配置列表  /config/playmode/list
export const getConfigPlayModeList = async (params: PageParams): Promise<ResultPlayMode> => {
  try {
    const response: ResultPlayMode = await api.get(`${apiHost}/app/config/playmode/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//查询配件配置列表 /app/config/accessory/list
export const getConfigAccessoryList = async (params: PageParams): Promise<ResultAccessory> => {
  try {
    const response: ResultAccessory = await api.get(`${apiHost}/app/config/accessory/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//获取预约时间段可用打位  /app/booking/order/bay
export const getBookingOrderBay = async (params: PageParams): Promise<ResultBay> => {
  try {
    const response: ResultBay = await api.get(`${apiHost}/app/booking/order/bay`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//  购物车数量  /app/booking/order/count
export const getBookingOrderCount = async (params: PageParams): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.get(`${apiHost}/app/booking/order/count`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//获取日期被占用打位 /app/booking/order/occupy/bay
export const getBookingOrderOccupyBay = async (params: PageParams): Promise<ResultBay> => {
  try {
    const response: ResultBay = await api.get(`${apiHost}/app/booking/order/occupy/bay`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//预约 /app/booking/order  post
export const bookingOrder = async (options: orderType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/app/booking/order`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 查询预约订单列表 /app/booking/order/list
export const getBookingOrderList = async (params: PageParams): Promise<ResultOrderList> => {
  try {
    const response: ResultOrderList = await api.get(`${apiHost}/app/booking/order/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

// 取消预约订单 /app/booking/order/{ids}
export const cancelBookingOrder = async (orderNo: string): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/app/booking/order/cancel/${orderNo}`)
    return response
  } catch (error: any) {
    return error
  }
}

// 删除订单 /app/booking/order/{ids}
export const deleteBookingOrder = async (ids: string): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.delete(`${apiHost}/app/booking/order/${ids}`)
    return response
  } catch (error: any) {
    return error
  }
}

// 根据获取折扣配置详细信息 /config/discount/code/{code}
export const getDiscountCode = async (code: string): Promise<ResultDiscount> => {
  try {
    const response: ResultDiscount = await api.get(`${apiHost}/app/config/discount/code/${code}`)
    return response
  } catch (error: any) {
    return error
  }
}

// 支付 /app/booking/order/payment
export const createOrderPayment = async (options: orderPaymentType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/app/booking/order/payment`, options)
    return response
  } catch (error: any) {
    return error
  }
}

//Square支付完成回调 /payment/callback/squarePaymentFinish
export const squarePaymentFinish = async (options: SquarePaymentType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/payment/callback/squarePaymentFinish`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 申请退款 /app/booking/order/refund/{orderNo}
export const refundBookingOrder = async (orderNo: string): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/app/booking/order/refund/${orderNo}`)
    return response
  } catch (error: any) {
    return error
  }
}

// 查询线下消费订单列表  /app/purchase/order/list
export const getPurchaseOrderList = async (params: PageParams): Promise<ResultPurchaseList> => {
  try {
    const response: ResultPurchaseList = await api.get(`${apiHost}/app/purchase/order/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

// 删除线下消费订单 /app/purchase/order/{id}
export const deletePurchaseOrder = async (id: string): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/app/purchase/order/${id}`)
    return response
  } catch (error: any) {
    return error
  }
}

// 获取当前用户交易记录 /trade/history/current
export const getCurrentUserTradeHistory = async (params: PageParams): Promise<ResultTradeList> => {
  try {
    const response: ResultTradeList = await api.get(`${apiHost}/trade/history/current`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}
