import Button from '@/component/Button'
import Input from '@/component/Input'
import { Message } from '@/component/Message'
import { validatRequired, validateEmail, validatePassword } from '@/helper/validationUtils'
import { captchaEmail, resetPwdByEmail } from '@/server/user'
import { ResetOption } from '@/server/user/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Md5 } from 'ts-md5'

// 定义组件的props接口
interface StepProps {
  onTypeChange: (type: string) => void
  email: string
  setEmail: (email: string) => void
}

const Reset: FC<StepProps> = ({ onTypeChange, email, setEmail }) => {
  const { t } = useTranslation('login')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})
  const [code, setVerifyCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [countdown, setCountdown] = useState(0)

  const handleSendVerificationCode = async () => {
    if (countdown > 0) return // 防止重复点击
    setCountdown(60)
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) clearInterval(timer)
        return prev - 1
      })
    }, 1000)

    // 模拟发送验证码请求
    const res = await captchaEmail(email)
    if (res.code === 200) {
      Message.success(t('login:btn.submitSuccess'))
    } else {
      Message.error(t('login:btn.submitFail'))
    }
  }

  const validate = () => {
    const newErrors: { [key: string]: string | undefined } = {}
    newErrors.email = validateEmail(email)
    newErrors.password = validatePassword(password)
    newErrors.confirmPassword = password !== confirmPassword ? 'Passwords do not match' : undefined
    newErrors.code = validatRequired(code)
    setErrors(newErrors)
    return Object.values(newErrors).every(error => !error)
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setLoading(true)
    const body: ResetOption = {
      email,
      password: Md5.hashStr(password),
      code
    }
    const newErrors: { [key: string]: string | undefined } = {}
    const res = await resetPwdByEmail(body)
    if (res.code === 200) {
      onTypeChange('sign')
      Message.success(t('login:btn.submitSuccess'))
    } else if (res.code === 2000) {
      newErrors.code = 'codeError'
    } else {
      Message.error(t('login:btn.submitFail'))
    }
    setLoading(false)
  }

  return (
    <div className="my-10 sm:my-14">
      <h3 className="text-white">{t('reset.title')}</h3>
      <p className="mt-2 text-[#c5c4c2]">
        {t('reset.tip')}
        <span className="cursor-pointer text-[#f7931a]" onClick={() => onTypeChange('sign')}>
          {t('btn.signIn')}
        </span>
      </p>
      <p className="my-7 text-sm text-[#c5c4c2]">{t('reset.desc')}</p>

      <Input
        label={t('inputLabel.email')}
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={errors.email}
      />
      <div className="flex w-full items-center space-x-4">
        <div className="flex-1">
          <Input
            label={t('inputLabel.emailVerifyCode')}
            value={code}
            onChange={e => setVerifyCode(e.target.value)}
            error={errors.code}
            className="w-full"
          />
        </div>
        <button
          onClick={handleSendVerificationCode}
          className="mt-2 h-[28px] w-auto rounded bg-[#f7931a] px-4 text-sm leading-none text-white"
          disabled={countdown > 0}
        >
          {countdown > 0 ? t('btn.resendCode', { countdown: countdown }) : t('btn.sendCode')}
        </button>
      </div>
      <Input
        label={t('inputLabel.password')}
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={errors.password}
      />
      <Input
        label={t('inputLabel.confirmPassword')}
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        error={errors.confirmPassword}
      />
      <div className="flex w-full justify-end">
        <Button loading={loading} onClick={handleSubmit} className="mt-6 rounded-full px-8 py-2 sm:py-3">
          {t('btn.reset')}
        </Button>
      </div>
    </div>
  )
}

export default Reset
