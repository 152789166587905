import { AuthContextType } from '@/type/auth'
import { createContext } from 'react'

const AuthContext = createContext<AuthContextType>({
  status: 'unready',
  user: null,
  cartCount: 0,
  init: () => new Promise(() => {}),
  login: () => new Promise(() => {}),
  logout: () => {},
  registe: () => new Promise(() => {}),
  accountRecover: () => new Promise(() => {}),
  updateUser: () => new Promise(() => {}),
  getUserInfo: () => new Promise(() => {}),
  getCartCount: () => new Promise(() => {})
})

export default AuthContext
