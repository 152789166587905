import { createFpId } from '@/helper'
import i18n from '@/i18n'
import axios, { AxiosInstance } from 'axios'

const api: AxiosInstance = axios.create({
  timeout: 5000
})

api.interceptors.request.use(
  async config => {
    // 在发送请求之前做些什么
    const Authorization = localStorage.getItem('Authorization')
    Authorization && (config.headers['Authorization'] = `Bearer ${Authorization}`)
    config.headers['Accept-Language'] = i18n.language
    const fpId = await createFpId()
    config.headers['fpId'] = fpId
    if (config.method === 'get' && config.params) {
      config.params = { ...config.params, multiLang: true }
    } else if (config.method === 'get') {
      config.params = { multiLang: true }
    }
    return config
  },
  (error: any) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    if (response.data.code === 401) {
      localStorage.removeItem('Authorization')
      console.log('过期')
      window.location.href = '/'
    } else if (response.data.code === 500) {
      // window.location.href = '/500'
    }
    return response.data
  },
  (error: any) => {
    // 对响应错误做些什么
    return Promise.reject(error)
  }
)

export default api
