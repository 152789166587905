import api from '@/config/api'
import { getBaseHost } from '@/helper'
import { LoginOptions } from '@/type/auth'
import { Result, ResultAny, ResultBoolean } from '@/type/common'
import {
  FeedbackOption,
  ResetOption,
  ResultTokenType,
  ResultUserType,
  ThirdType,
  UserType,
  optionsEmailCode
} from './types'

const apiHost = getBaseHost(process.env.NODE_ENV)

export const exsits = async (options: optionsEmailCode): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.post(`${apiHost}/exsits`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const login = async (options: LoginOptions): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/login`, options)
    return response
  } catch (error: any) {
    return error
  }
}
export const getInfo = async (): Promise<ResultUserType> => {
  try {
    const response: ResultUserType = await api.get(`${apiHost}/getInfo`)
    return response
  } catch (error: any) {
    return error
  }
}

export const register = async (options: UserType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/register`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const resetPwdByEmail = async (options: ResetOption): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/resetPwdByEmail`, options)
    return response
  } catch (error: any) {
    return error
  }
}
// /portal/feedback post
export const feedback = async (options: FeedbackOption): Promise<Result> => {
  try {
    const response: Result = await api.post(`${apiHost}/portal/feedback`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const captchaEmail = async (email: string): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.get(`${apiHost}/captchaEmail`, {
      params: {
        email: email
      }
    })
    return response
  } catch (error: any) {
    return error
  }
}

// 修改用户 put /system/user/profile
export const updateProfile = async (options: {
  lastName: string
  firstName: string
  phonenumber: string
}): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 修改密码 /system/user/profile/updatePwd
export const updatePwd = async (options: { password: string }): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/updatePwd`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 获取Google登录链接  /thirdparty/google/redirect body redirectUri
export const getGoogleLoginUrl = async (body: ThirdType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/thirdparty/google/redirect`, body)
    return response
  } catch (error: any) {
    return error
  }
}

//获取Facebook登录链接 /thirdparty/facebook/redirect
export const getFacebookLoginUrl = async (body: ThirdType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/thirdparty/facebook/redirect`, body)
    return response
  } catch (error: any) {
    return error
  }
}

// Google第三方登录  /googleLogin
export const googleLogin = async (body: ThirdType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/googleLogin`, body)
    return response
  } catch (error: any) {
    return error
  }
}

//Facebook第三方登录 /facebookLogin
export const facebookLogin = async (body: ThirdType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/facebookLogin`, body)
    return response
  } catch (error: any) {
    return error
  }
}
//获取苹果redirectURI，用于在sdk中调用/thirdparty/apple/redirect
export const getAppleLoginUrl = async (body: ThirdType): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/thirdparty/apple/redirect`, body)
    return response
  } catch (error: any) {
    return error
  }
}

// /appleLogin
export const appleLogin = async (body: ThirdType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/appleLogin`, body)
    return response
  } catch (error: any) {
    return error
  }
}
