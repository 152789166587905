import { ReactComponent as FBSVG } from '@/asset/svg/fb.svg'
import { ReactComponent as GoogleSVG } from '@/asset/svg/google.svg'
import AppleLogin from '@/component/AppleLogin'
import Button from '@/component/Button'
import Input from '@/component/Input'
import { Message } from '@/component/Message'
import AuthContext from '@/context/AuthContext'
import { validateEmail, validatePassword } from '@/helper/validationUtils'
import { getFacebookLoginUrl, getGoogleLoginUrl, login } from '@/server/user'
import { LoginOptions } from '@/type/auth'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next' // 假设使用react-i18next
import { Md5 } from 'ts-md5'
import Line from '../Line'

const channelList = [
  {
    img: <GoogleSVG fill="white" />,
    title: 'socialLogin.google',
    href: '/api/auth/google',
    type: 'google'
  },
  {
    img: <FBSVG fill="white" />,
    title: 'socialLogin.facebook',
    href: '/api/auth/facebook',
    type: 'facebook'
  }
]

interface props {
  onTypeChange: (type: string) => void
  onClose: () => void
}

const Sign: React.FC<props> = ({ onTypeChange, onClose }) => {
  const { t } = useTranslation('login')
  const auth = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({})

  const validate = () => {
    const newErrors: { [key: string]: string | undefined } = {}
    newErrors.email = validateEmail(email)
    newErrors.password = validatePassword(password)
    setErrors(newErrors)
    return Object.values(newErrors).every(error => !error)
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setLoading(true)
    const body: LoginOptions = {
      username: email,
      password: Md5.hashStr(password)
    }
    const newErrors: { [key: string]: string | undefined } = {}
    const res = await login(body)
    if (res.code === 200) {
      Message.success(t('loginSuccess'))
      localStorage.setItem('Authorization', res.data.token)
      await auth.getUserInfo()
      onClose()
    } else if (res.code === 20004) {
      newErrors.email = 'exists'
    } else if (res.code === 20005) {
      newErrors.password = 'correct'
    }
    setErrors(newErrors)
    setLoading(false)
  }

  const goThird = async (type: string) => {
    const body = {
      redirectUri: window.location.origin + '/en/third',
      state: type + ',' + window.location.pathname
    }

    if (type === 'google') {
      const res = await getGoogleLoginUrl(body)
      if (res.code === 200) {
        window.location.href = res.data
      }
    } else if (type === 'facebook') {
      const res = await getFacebookLoginUrl(body)
      if (res.code === 200) {
        window.location.href = res.data
      }
    }
  }

  return (
    <div className="my-10 sm:my-14">
      <h3 className="font-bold text-white ">{t('sign.title')}</h3>
      <p className="my-3 text-[#c5c4c2]/80">{t('sign.tip')}</p>
      <Input
        label={t('inputLabel.email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={errors.email}
      />
      <Input
        label={t('inputLabel.password')}
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={errors.password}
      />
      <div className="flex w-full justify-end">
        <Button
          loading={loading}
          onClick={handleSubmit}
          className="mt-6 rounded-full px-8 py-2 font-bold italic sm:py-3"
        >
          {t('btn.signIn')}
        </Button>
      </div>
      <p className="mt-2 text-center text-[#c5c4c2] sm:mt-6">
        {t('forgotPassword')}
        <span
          onClick={() => onTypeChange('reset')}
          className="cursor-pointer font-bold italic text-white underline decoration-[#f7931a] underline-offset-4"
        >
          {t('resetPassword')}
        </span>
      </p>
      <p className="text-center text-[#c5c4c2]">{t('noAccount')}</p>

      <Line />

      {channelList.map(item => (
        <button
          key={item.type}
          className="mb-4 flex w-full items-center justify-center rounded-full border border-[#c5c4c2] py-2 hover:brightness-95 sm:py-4"
          onClick={() => goThird(item.type)}
        >
          {item.img}
          <span className="ml-4 text-white">{t(item.title)}</span>
        </button>
      ))}
      <AppleLogin />
      <p className="mt-4 text-[#c5c4c2]">
        {t('newUser')}
        <span className="cursor-pointer text-[#f7931a]" onClick={() => onTypeChange('exsits')}>
          {t('createAccount')}
        </span>
      </p>
    </div>
  )
}

export default Sign
