import React, { ButtonHTMLAttributes, FC } from 'react'

// 定义类型接口
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode // 按钮的内容
  loading?: boolean // 是否加载中
  disabled?: boolean // 是否禁用
  className?: string // 自定义类名
}

// 创建 Button 组件
const Button: FC<ButtonProps> = ({ children, loading, disabled, className, ...props }) => {
  return (
    <button
      className={` text-white  ${className} ${disabled || loading ? 'bg-[#58524a] text-[#3d3832]' : 'bg-[#f7931a] hover:brightness-95'}`}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <span className="flex items-center justify-center">
          LOADING
          <svg
            className="ml-1 h-5 w-5 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-50" cx="12" cy="12" r="10" stroke="black" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
        </span>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
