import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  onTypeChange: (type: string) => void
  current: number
}

const Step: React.FC<props> = ({ onTypeChange, current }) => {
  const { t } = useTranslation('login')
  return (
    <div>
      <p className="text-sm text-[#c5c4c2]">{t('step.current', { current: current })}</p>
      <h3 className="font-bold text-white">{t('step.title')}</h3>
      <p className="mt-2 text-[#c5c4c2]">
        {t('reset.tip')}
        <span className="cursor-pointer text-[#f7931a]" onClick={() => onTypeChange('sign')}>
          {t('reset.sign')}
        </span>
      </p>
    </div>
  )
}

export default Step
