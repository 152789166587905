import { useMatch, useNavigate } from 'react-router-dom'

const useLangNavigate = () => {
  const navigate = useNavigate()
  const match = useMatch('/:lang/*')
  const params = match?.params

  const langNavigate = (path: string, openInNewTab = false) => {
    const fullPath = `/${params?.lang || 'en'}${path}`

    if (openInNewTab) {
      window.open(fullPath, '_blank')
    } else {
      navigate(fullPath)
    }
  }

  return langNavigate
}

export default useLangNavigate
