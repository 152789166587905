import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import React, { MouseEvent } from 'react'

// 定义类型接口
interface ModalProps {
  visible: boolean // 控制 Modal 是否可见
  onClose: () => void // 关闭 Modal 的回调函数
  children: React.ReactNode // Modal 内容
}

const Modal: React.FC<ModalProps> = ({ visible, onClose, children }) => {
  if (!visible) return null
  const handleOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }
  return (
    <div
      className=" fixed inset-0 z-[999] flex  items-start justify-center bg-black bg-opacity-50"
      onClick={handleOutsideClick}
    >
      <div className=" relative top-0 w-[510px] rounded-md bg-[#3d3832] px-14 shadow-lg sm:mt-20">
        <button
          className="absolute right-4 top-4 p-1 text-gray-500 hover:text-gray-800 focus:outline-none"
          onClick={onClose}
        >
          <CloseSVG className="h-6 w-6" fill="white" />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
