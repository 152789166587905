import { ReactComponent as FacebookSVG } from '@/asset/svg/Facebook.svg'
import { ReactComponent as InstagramSVG } from '@/asset/svg/Instagram.svg'
import { ReactComponent as DownwardSVG } from '@/asset/svg/downward.svg'
import { ReactComponent as LanguageSVG } from '@/asset/svg/language.svg'
import { ReactComponent as UserSVG } from '@/asset/svg/user.svg'
import { ReactComponent as YoutubeSVG } from '@/asset/svg/youtube.svg'
import LinkEnhance from '@/component/LinkEnhance'
import AuthContext from '@/context/AuthContext'

import { PopUpStore } from '@/store/PopupStore'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NavFooter: React.FC<PropType> = ({ onClose }) => {
  const { t, i18n } = useTranslation()
  const auth = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const handleClick = () => {
    PopUpStore.setLoginPopupVisible(true)
  }
  const handleSelect = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem('lang', language)
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(/\/(en|zh)\//, `/${language}/`)
    navigate(newPath)
    setIsOpen(false)
  }

  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName)
  }

  const handleMouseLeave = () => {
    setHoveredIcon(null)
  }

  const handleIconClick = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div className="fixed bottom-14 w-full px-7">
      {auth.cartCount > 0 && (
        <LinkEnhance href="/cart">
          <div className="mb-3 flex h-[49px] w-full items-center justify-center rounded-[6px] border border-white bg-transparent font-bold text-white">
            {t('btn.cart')}
          </div>
        </LinkEnhance>
      )}
      {auth.user ? (
        <>
          <button className=" h-[49px] w-full rounded-[6px] bg-[#f7931a] font-bold text-white" onClick={auth.logout}>
            {t('btn.loginOut')}
          </button>
        </>
      ) : (
        <>
          <div className="flex cursor-pointer items-center hover:text-[#f7931a]" onClick={handleClick}>
            <UserSVG className="mr-3 h-6 w-6" />
            {t('btn.login')}
          </div>
        </>
      )}
      <div className="mt-6 flex cursor-pointer items-center hover:text-[#f7931a]" onClick={toggleDropdown}>
        <LanguageSVG className="mr-3 h-6 w-6" />
        {i18n.language === 'zh' ? t('btn.chinese') : t('btn.english')}
        <DownwardSVG className="ml-2 h-6 w-6" />
      </div>
      {isOpen && (
        <div className="absolute left-5 z-20 -mt-28 w-40 rounded-md bg-[#000000]/80 shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1 text-center" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a
              href="#"
              className="block px-4 py-2 text-sm text-white hover:text-[#f7931a]"
              onClick={() => handleSelect('zh')}
            >
              {t('btn.chinese')}
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-white hover:text-[#f7931a]"
              onClick={() => handleSelect('en')}
            >
              {t('btn.english')}
            </a>
          </div>
        </div>
      )}

      <div className="mt-10 flex justify-around">
        <div
          className="cursor-pointer"
          onMouseEnter={() => handleMouseEnter('Facebook')}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleIconClick('https://www.facebook.com/share/VcJSx2VTwoh9ztTZ/?mibextid=LQQJ4d')}
        >
          <FacebookSVG fill={hoveredIcon === 'Facebook' ? '#f7931a' : '#ffffff'} />
        </div>
        <div
          className="cursor-pointer"
          onMouseEnter={() => handleMouseEnter('Instagram')}
          onMouseLeave={handleMouseLeave}
          onClick={() =>
            handleIconClick('https://www.instagram.com/outtabounds_golf?igsh=MXhtcG04bG9neWIwZQ%3D%3D&utm_source=qr')
          }
        >
          <InstagramSVG fill={hoveredIcon === 'Instagram' ? '#f7931a' : '#ffffff'} />
        </div>
        <div
          className="cursor-pointer"
          onMouseEnter={() => handleMouseEnter('TikTok')}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleIconClick('https://youtube.com/@outtaboundsgolfau?feature=shared')}
        >
          <YoutubeSVG fill={hoveredIcon === 'TikTok' ? '#f7931a' : '#ffffff'} />
        </div>
      </div>
    </div>
  )
}

interface PropType {
  onClose: () => void
}

export default NavFooter
