import { ReactComponent as AppleSVG } from '@/asset/svg/apple.svg'
import { ReactComponent as Apple2SVG } from '@/asset/svg/apple2.svg'
import { getAppleLoginUrl } from '@/server/user'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface AppleLoginProps {
  onlyImg?: boolean
}
const AppleLogin: React.FC<AppleLoginProps> = ({ onlyImg }) => {
  const { t } = useTranslation('login')
  useEffect(() => {
    // 加载 Apple ID 登录 SDK
    getUrl()
  }, [])

  const handleLogin = () => {
    window.AppleID.auth.signIn()
  }

  const getUrl = async () => {
    const body = {
      redirectUri: window.location.origin + '/en/third',
      state: 'apple,' + window.location.pathname
    }
    const res = await getAppleLoginUrl(body)
    if (res.code === 200) {
      const script = document.createElement('script')
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      script.async = true

      script.onload = () => {
        window.AppleID.auth.init({
          clientId: 'au.outtabounds.signInApple',
          scope: 'name email',
          redirectURI: res.data,
          state: 'apple'
        })
      }
      document.body.appendChild(script)
    }
  }

  return (
    <>
      {onlyImg ? (
        <div onClick={handleLogin} className=" cursor-pointer">
          <Apple2SVG fill="white" />
        </div>
      ) : (
        <div
          onClick={handleLogin}
          className="mb-4 flex w-full cursor-pointer items-center justify-center rounded-full border border-[#c5c4c2] py-2 hover:brightness-95 sm:py-4"
        >
          <AppleSVG fill="white" />
          <span className="ml-4 text-white">{t('socialLogin.apple')}</span>
        </div>
      )}
    </>
  )
}

export default AppleLogin
