import React from 'react'

import { ReactComponent as LogoSVG } from '@/asset/svg/logo.svg'
import LinkEnhance from '@/component/LinkEnhance'

const Logo: React.FC<PropType> = () => {
  return (
    <h1>
      <LinkEnhance>
        <LogoSVG className=" mb-6 w-16 fill-text-dark" />
      </LinkEnhance>
    </h1>
  )
}

interface PropType {}

export default Logo
