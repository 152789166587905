import { createFpId } from '@/helper'
import { getBookingOrderCount } from '@/server/app'
import { getInfo } from '@/server/user'
import { UserType } from '@/server/user/types'
import {
  AccountRecoverOptions,
  AuthContextType,
  AuthStatus,
  LoginOptions,
  RegisterOptions,
  UpdateUserOptions
} from '@/type/auth'
import { useMemo, useState } from 'react'

const useProvideAuth = (): AuthContextType => {
  const [status, setStatus] = useState<AuthStatus>('unready')
  const [user, setUser] = useState<UserType | null>(null)
  const [cartCount, setCartCount] = useState<number>(0)

  // 获取最新购物车数量
  const getCartCount = async (): Promise<void> => {
    console.log('getCartCount', user)
    const fpId = await createFpId()
    console.log('getCartCount', user ? '' : fpId)

    const res = await getBookingOrderCount({
      status: 0,
      fpId: user ? '' : fpId
    })
    if (res.code === 200) {
      setCartCount(res.data)
    }
  }

  const getUserInfo = async (): Promise<void> => {
    const res: any = await getInfo()
    if (res.code === 200) {
      setUser(res.data.user)
      setStatus('authorized')
    } else {
      setStatus('unauthorized')
    }
  }

  // 用户认证初始化
  const init = async () => {
    if (localStorage.getItem('Authorization') === null) {
      setStatus('unauthorized')
      await getCartCount()
      return
    }
    await getUserInfo()
    await getCartCount()
  }

  // 登录
  const login = async (options: LoginOptions) => {
    // 普通帐密登录
    // 获取用户信息
    await getUserInfo()
  }

  // 登出
  const logout = () => {
    setUser(null)
    setStatus('unauthorized')
    localStorage.removeItem('Authorization')
  }

  // 注册
  const registe = async (options: RegisterOptions) => {
    // 用户注册
    // 获取用户信息
    await getUserInfo()
  }

  // 找回密码
  const accountRecover = async (options: AccountRecoverOptions) => {
    // await TGASDK.module.UC.service.resetPassword(options)
  }

  // 更新用户信息
  const updateUser = async (options: UpdateUserOptions) => {
    // 获取用户信息
    await getUserInfo()
  }

  return useMemo(
    () => ({
      status,
      user,
      cartCount,
      init,
      login,
      logout,
      registe,
      accountRecover,
      updateUser,
      getUserInfo,
      getCartCount
    }),
    [status, user, cartCount]
  )
}

export default useProvideAuth
