import React from 'react'
import { type RouteObject } from 'react-router-dom'

import MainLayout from '@/component/layout/MainLayout'
import RedirectLayout from '@/component/layout/RedirectLayout'

const AuthRequired = React.lazy(() => import('@/component/AuthRequired'))

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        // 处理跳转
        index: true,
        element: <RedirectLayout />
      },
      {
        path: ':lang/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            lazy: async () => {
              return {
                Component: (await import('../page/Home')).default
              }
            }
          },
          {
            path: 'third/',
            lazy: async () => {
              return {
                Component: (await import('../page/Third')).default
              }
            }
          },
          {
            path: 'book/',
            lazy: async () => {
              return {
                Component: (await import('../page/Book')).default
              }
            }
          },
          {
            path: 'contact/',
            lazy: async () => {
              return {
                Component: (await import('../page/Contact')).default
              }
            }
          },
          {
            path: 'memberships/',
            lazy: async () => {
              return {
                Component: (await import('../page/Memberships')).default
              }
            }
          },
          {
            path: 'topup/',
            lazy: async () => {
              return {
                Component: (await import('../page/Topup/List')).default
              }
            }
          },
          {
            path: 'topup/buy/',
            lazy: async () => {
              return {
                Component: (await import('../page/Topup/Buy')).default
              }
            }
          },
          {
            path: 'memberships/buy/',
            lazy: async () => {
              return {
                Component: (await import('../page/Memberships/Buy')).default
              }
            }
          },
          {
            path: 'memberships/list/',
            lazy: async () => {
              return {
                Component: (await import('../page/Memberships/List')).default
              }
            }
          },
          {
            path: 'join/',
            lazy: async () => {
              return {
                Component: (await import('../page/Join')).default
              }
            }
          },
          {
            path: 'cart/',
            lazy: async () => {
              return {
                Component: (await import('../page/Cart')).default
              }
            }
          },
          {
            path: 'grub/',
            lazy: async () => {
              return {
                Component: (await import('../page/Grub')).default
              }
            }
          },
          {
            path: 'location/',
            lazy: async () => {
              return {
                Component: (await import('../page/Location')).default
              }
            }
          },
          {
            path: 'parties/',
            lazy: async () => {
              return {
                Component: (await import('../page/Parties')).default
              }
            }
          },
          {
            path: 'pricing/',
            lazy: async () => {
              return {
                Component: (await import('../page/Pricing')).default
              }
            }
          },
          {
            path: 'result/',
            lazy: async () => {
              return {
                Component: (await import('../page/Result')).default
              }
            }
          },
          {
            path: 'privacy/',
            lazy: async () => {
              return {
                Component: (await import('../page/Privacy')).default
              }
            }
          },
          {
            path: 'terms/',
            lazy: async () => {
              return {
                Component: (await import('../page/Terms')).default
              }
            }
          },
          {
            path: 'privacy/',
            lazy: async () => {
              return {
                Component: (await import('../page/Privacy')).default
              }
            }
          },
          {
            path: 'profile/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Profile')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'bookings/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Bookings')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'membershipsRecords/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Memberships/Records')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'wallet/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Wallet')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'purchases/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Purchases')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'transactions/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Transactions')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'integral/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Integral')).default}</AuthRequired>
              }
            }
          }
        ]
      },
      {
        path: '*',
        element: <RedirectLayout />
      }
    ]
  }
]

export default routes
